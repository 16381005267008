// EmployeeGrid.js
import React from 'react';
import { Grid } from '@mui/material';
import "../Styles/Employee.css";


function EmployeeGrid({ data }) {
  const departments = Array.from(new Set(data.map(employee => employee.department)));

  return (
    <div className="employee-grid-container">
      {departments.map((department, index) => (
        <div key={index} className="department-container">
         <h3 className="dt-title">
          <span>{department}</span>
        </h3>
          <div container spacing={0} className="employee-grid">
            {data
              .filter(employee => employee.department === department)
              .map((employee, index) => (
                <Grid item xs={2} key={index}>
                  <div className="employee-paper">
                    <p className="dt-card-name">{employee.name}</p>
                    <p className="dt-card-title" >{employee.position}</p>
                    <p variant="body2">{employee.mail}</p>
                  </div>
                </Grid>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default EmployeeGrid;
