import React from "react";
import DoctorCard from "./DoctorCard";
import profile2 from "../Assets/ceo.jpeg";
import "../Styles/Doctors.css";

function Doctors() {
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Making Idea's Happen.</span>
        </h3>

        <p className="dt-description">
      I'm the Founder and Chief Executive Officer here at Expertzon. I'm truly passionate about my company growth, and it's been an incredible journey steering Expertzon toward success.
        </p>
      </div>

      <div className="dt-cards-content">
     
        <DoctorCard
          img={profile2}
          name="Mr. Afsar Syed"
          title="CEO & Product Manager"
          email="Afsar@expertzon.com"
        />
       
        {/* <DoctorCard
          img={profile4}
          name="Dr. Albert Flores"
          title="Hematologists"
          stars="4.8"
          reviews="500"
        /> */}
      </div>
    </div>
  );
}

export default Doctors;
