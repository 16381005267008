import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import About from "../Components/About";
import BookAppointment from "../Components/BookAppointment";
import Reviews from "../Components/Reviews";
import Doctors from "../Components/Doctors";
import Footer from "../Components/Footer";
import EmployeeGrid from "../Components/Employees";

function Home() {
   const allEmployeeData = [
    // Software Developers
    { department: 'Software Developers', name: 'Alex Soren', position: 'Team Lead', mail: 'alex@expertzon.com' },
    { department: 'Software Developers', name: 'Sunitha Rani', position: 'Java Developer', mail: 'sunitha@expertzon.com' },
    { department: 'Software Developers', name: 'Habeeb Syed', position: 'Java Developer', mail: 'habeeb@expertzon.com' },
    { department: 'Software Developers', name: 'Santosh Sattershetty', position: 'Dot Net Developer', mail: 'santosh@expertzon.com' },
    { department: 'Software Developers', name: 'Arbaz Mohammed', position: 'Full Stack developer', mail: 'arbaz@expertzon.com' },
    { department: 'Software Developers', name: 'Uma Devi', position: 'AI/ML', mail: 'uma@expertzon.com' },

    // Testing Team
    { department: 'Testing Team', name: 'Usha Rani', position: 'Test Lead', mail: 'usha@expertzon.com' },
    { department: 'Testing Team', name: 'Aslam Khan', position: 'Quality Testing Analyst', mail: 'aslam@expertzon.com' },
    { department: 'Testing Team', name: 'Priyanka Varma', position: 'Quality Test Engineer', mail: 'priyanka@expertzon.com' },

    // UI/UX Designers
    { department: 'UI/UX Designers', name: 'Kevin Gentile', position: 'Senior Web Designer', mail: 'kevin@expertzon.com' },
    { department: 'UI/UX Designers', name: 'Mustafa Baig', position: 'Web Designer', mail: 'mustafa@expertzon.com' },

    // Accounts Team
    { department: 'Accounts Team', name: 'Vara Prasad', position: 'Accountant', mail: 'vara@expertzon.com' },

    // Branch Office Ontaria
    { department: 'Branch Office Ontario', name: 'Shiva Kumar', position: 'Branch Manager', mail: 'shiva@expertzon.com' },
    { department: 'Branch Office Nova Scotia', name: 'Shaik Omer', position: 'Branch Manager', mail: 'Omer@expertzon.com' },
    { department: 'Branch Office Hyderabad,India', name: 'Ali Syed', position: 'Branch Manager', mail: 'Ali.Syed@expertzon.com' },
  ];
  return (
    <div className="home-section">
      <Navbar />
      <Hero />
      <Info />
      <About />
      <BookAppointment />
      {/* <Reviews /> */}
      <Doctors />
       <EmployeeGrid data={allEmployeeData} />
      <Footer />
    </div>
  );
}

export default Home;
